import * as React from "react";
import { Interpolation } from "@emotion/core";
import {
  EventAttributes,
  pushActionableClickEvent,
} from "../../../utils/analytics";
import { ENTER } from "../../../constants/key-code";

export interface ElementProps extends React.HTMLProps<HTMLElement> {
  component?: keyof React.ReactHTML;
}

const Element = ({ component, ...otherProps }: ElementProps) =>
  React.createElement(component || "div", otherProps);

const style: Interpolation = {
  cursor: "pointer",
  WebkitTapHighlightColor: "transparent",

  "&:focus": {
    outline: "0",
    boxShadow: `0`,
  },

  "&:active": {
    outline: "0",
  },

  "> *": {
    pointerEvent: "none",
  },
};

// https://www.w3.org/TR/wai-aria-1.1/#role_definitions
type UsablilityRole =
  | "button"
  | "link"
  | "checkbox"
  | "radio"
  | "select"
  | "option"
  | "listitem";

export interface ActionableProps extends ElementProps {
  onClick?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  role: UsablilityRole;
  ariaLabel: string;
  css?: Interpolation;
  analytics: EventAttributes;
}

const Actionable: React.FC<ActionableProps> = ({
  component,
  analytics,
  role,
  tabIndex,
  ariaLabel,
  className,
  onClick,
  onKeyDown,
  ...otherProps
}) => {
  const handleClick = (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (onClick) {
      pushActionableClickEvent(analytics);
      onClick(event);
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (onKeyDown) {
      onKeyDown(event);
    }

    if (event.keyCode === ENTER && onClick) {
      handleClick();
    }
  };

  return (
    <Element
      component={component}
      role={role}
      tabIndex={tabIndex || 0}
      aria-label={ariaLabel}
      css={style}
      className={className}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      {...otherProps}
    />
  );
};

export default Actionable;
