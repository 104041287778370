import { Interpolation } from "@emotion/core";
import { Fonts } from "../../design-tokens/fonts";
import { Border } from "../../design-tokens/borders";
import { Colors } from "../../design-tokens/colors";
import { grid } from "../../design-tokens/grid";
import { DarkTheme, Device } from "../../design-tokens/media-queries";

const container: Interpolation = {
  display: "inline-flex",
  alignItems: "center",
  minHeight: grid(3.5),
  maxWidth: "calc(100% - 40px)",
  fontSize: "12px",
  fontWeight: Fonts.Weight.SemiBold,
  padding: `0 ${grid(1.5)}`,
  border: `1px solid ${Colors.BorderGrey}`,
  borderTopLeftRadius: Border.RadiusLg,
  borderBottomRightRadius: Border.RadiusLg,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "elipsis",

  [DarkTheme]: {
    border: `1px solid ${Colors.DarkModeBorder}`,
  },

  [Device.MobilePortXs]: {
    fontSize: "10px",
  },
};

const sourceUrl: Interpolation = {
  color: Colors.Blue,
  marginLeft: grid(0.5),

  [DarkTheme]: {
    color: Colors.DarkModeTextBlue,
  },

  "&:hover": {
    textDecoration: "none",
  },
};

const styles = {
  container,
  sourceUrl,
};

export default styles;
