import * as React from "react";
import TwitterIcon from "../icons/twitter.svg";
import FacebookIcon from "../icons/facebook.svg";
import LinkedInIcon from "../icons/linkedin.svg";
import ShareIcon from "../icons/share-icon.svg";
import ClearIcon from "../icons/clear-icon-blue.svg";
import Image from "../image/image";
import {
  pushActionableClickEvent,
  EventCategory,
} from "../../../utils/analytics";
import styles from "./social-share.styles";

interface SocialShareProps {
  title: string;
  description: string;
  url: string;
}

const SocialShare: React.FC<SocialShareProps> = ({
  title,
  description,
  url,
}) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);

  const pageUrl = `https://askabout.io${url}`;
  const titleText = `${title} - Ask About`;

  const facebookUri = `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`;
  const twitterUri = encodeURI(
    `https://twitter.com/intent/tweet?text=${titleText} ${pageUrl}`
  );
  const linkedinUri = encodeURI(
    `https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}&title=${titleText}&summary=${description}&source=${pageUrl}`
  );

  const handleClickShare = (label: string) => {
    setOpen(!isOpen);
    pushActionableClickEvent({
      category: EventCategory.IntentShare,
      action: `Click - ${label}`,
      label,
    });
  };

  return (
    <div css={styles.root}>
      <a
        href={twitterUri}
        target="_blank"
        rel="noreferrer"
        css={[styles.button, !isOpen && styles.hidden]}
        aria-label="Share on Twitter"
        onClick={() => handleClickShare("Twitter")}
      >
        <Image src={TwitterIcon} alt="Share on Twitter" />
      </a>
      <a
        href={facebookUri}
        target="_blank"
        rel="noreferrer"
        css={[styles.button, !isOpen && styles.hidden]}
        aria-label="Share on Facebook"
        onClick={() => handleClickShare("Facebook")}
      >
        <Image src={FacebookIcon} alt="Share on Facebook" />
      </a>
      <a
        href={linkedinUri}
        target="_blank"
        rel="noreferrer"
        css={[styles.button, !isOpen && styles.hidden]}
        aria-label="Share on LinkedIn"
        onClick={() => handleClickShare("LinkedIn")}
      >
        <Image src={LinkedInIcon} alt="Share on LinkedIn" />
      </a>

      <button
        css={[styles.button, isOpen && styles.closeButton]}
        aria-label="Share"
        onClick={() => handleClickShare(!isOpen ? "Share" : "Close")}
      >
        {!isOpen ? (
          <Image src={ShareIcon} alt="Share" />
        ) : (
          <Image src={ClearIcon} alt="Share" />
        )}
      </button>
    </div>
  );
};

export default SocialShare;
