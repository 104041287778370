import { Interpolation } from "@emotion/core";
import { Colors } from "../../design-tokens/colors";
import { grid } from "../../design-tokens/grid";
import { Keyframes } from "../../design-tokens/animation";

const root: Interpolation = {
  position: "fixed",
  bottom: grid(2.5),
  right: grid(2.5),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: grid(5),
  zIndex: 100,

  ["@media (min-width: 1160px)"]: {
    right: "calc(50% - 550px)",
  },
};

const button: Interpolation = {
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: grid(5),
  height: grid(5),
  padding: 12,
  backgroundColor: Colors.Blue,
  borderRadius: "50%",
  border: 0,
  boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.1)",
  transition: "all 0.2s ease",
  marginTop: grid(1.5),
  animation: `${Keyframes.FadeIn} 0.2s linear`,

  "> img": {
    maxWidth: "100%",
    maxHeight: "100%",
  },

  "&:active": {
    outline: 0,
    border: 0,
  },

  "&:focus": {
    outline: 0,
  },
};

const hidden: Interpolation = {
  display: "none",
};

const closeButton: Interpolation = {
  width: grid(4),
  height: grid(4),
  backgroundColor: Colors.White,
};

const styles = {
  root,
  button,
  hidden,
  closeButton,
};

export default styles;
