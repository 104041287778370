import * as React from "react";
import ContentCard from "../../../../ui-library/content-card/content-card";
import { RainBow } from "../../../../design-tokens/colors";
import CardContent from "../../../../ui-library/content-card/card-content";
import CardHeading from "../../../../ui-library/content-card/card-heading";
import CardSubHeading from "../../../../ui-library/content-card/card-sub-heading";
import TimelinePoint from "../../../../ui-library/timeline-point/timeline-point";
import { EventCategory } from "../../../../../utils/analytics";
import LinkButton from "../../../../ui-library/link-button/link-button";
import Row from "../../../../ui-library/column-system/row";
import Column from "../../../../ui-library/column-system/column";
import { AskAboutContext } from "../../../../../site-context/ask-about-context";
import { getCOVIDIntentUrl } from "../../../../../utils/question-url";
import { SPECIAL_INTENTS } from "../../../../../constants/special-intents";
import { COVID_TIMELINE } from "./covid-timeline-data";

const colorMapping: { [key: number]: RainBow } = {
  12: RainBow.Pink,
  1: RainBow.Plum,
  2: RainBow.Grape,
  3: RainBow.BlueBerry,
  4: RainBow.SkyBlue,
  5: RainBow.Teal,
};

const CovidTimelineCard: React.FC = () => {
  const {
    store: { isMobile },
  } = React.useContext(AskAboutContext);

  return (
    <ContentCard>
      <CardContent>
        <Row>
          <Column spanLg={9} spanMd={8} spanSm={12} isNoMarginBottom={true}>
            <div>
              <CardHeading>COVID-19 pandemic timeline</CardHeading>

              <CardSubHeading>
                A comprehensive timeline of the new coronavirus pandemic, from
                China's first COVID-19 case to the present
              </CardSubHeading>

              <LinkButton
                href={getCOVIDIntentUrl(SPECIAL_INTENTS.TimeLine.alias)}
                isCenter={isMobile}
                analytics={{
                  category: EventCategory.Section_Timeline,
                  action: "Click - Explore timeline",
                  label: "Explore timeline",
                }}
              >
                Explore timeline
              </LinkButton>
            </div>
          </Column>
          {!isMobile && (
            <Column spanLg={3} spanMd={4} isNoMarginBottom={true}>
              <div>
                {COVID_TIMELINE.slice(0, 3).map((event, index) => {
                  const date = new Date(event.date);

                  const color =
                    colorMapping[date.getMonth() + 1] || RainBow.SkyBlue;

                  return (
                    <TimelinePoint
                      key={event.label.slice(0, 16)}
                      date={event.date}
                      color={color}
                      showYear={index === 0 || index === 1}
                    />
                  );
                })}
              </div>
            </Column>
          )}
        </Row>
      </CardContent>
    </ContentCard>
  );
};

export default CovidTimelineCard;
