import * as React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo/seo";
import Container from "../../components/ui-library/container/container";
import { LogoBunny } from "../../components/site/header/header";
import CollectQAndA from "../../components/common-page-sections/collect-q-and-a/collect-q-and-a";
import SocialShare from "../../components/ui-library/social-share/social-share";
import PageHeading from "../../components/ui-library/page-heading/page-heading";
import PageContainer from "../../components/ui-library/page-container/page-container";
import Navigation from "../../components/site/navigation/navigation";
import { TOP_NAV_ITEM } from "../../constants/top-navigations";
import CountriesControlSnapshots from "../../components/covid-19/covid-intent/intent-specific-answers/countries-control-snapshots/countries-control-snapshots";
import { Topic } from "../../types/topics";
import socialImage from "../../images/ask-about-social-covid.jpg";
import WorldSnapshot from "../../components/covid-19/covid-intent/intent-specific-answers/daily-case-by-country/world-snapshot";
import CountriesControlSnapshotsWithTest from "../../components/covid-19/covid-intent/intent-specific-answers/countries-control-snapshots/countries-control-snapshots-with-test";
import CountriesInfectionRates from "../../components/covid-19/covid-intent/intent-specific-answers/countries-control-snapshots/countries-infection-rates";
import CovidTimelineCard from "../../components/covid-19/covid-intent/intent-specific-answers/covid-19-timeline/covid-timeline-card";
import ECVBanner from "../../components/covid-19/third-party-banner/ecv-banner";

const Covid19InsightsPage: React.FC = () => (
  <Layout
    topic={Topic.COVID19}
    description="coronavirus"
    headerUrl="/covid-19/"
    logoBunny={LogoBunny.FaceMask}
  >
    <SEO
      title="COVID-19 insights"
      description="COVID-19 realtime data, statistical insights, event timeline. See which countries are doing well and which ones are not."
      imageUrl={socialImage}
    />

    <Navigation topic={Topic.COVID19} activeItem={TOP_NAV_ITEM.Insights} />

    <PageContainer>
      <PageHeading>What insights can you show me?</PageHeading>

      <Container fullWidthOnMobile={true}>
        <CountriesControlSnapshots top4Only={true} />
        <CountriesControlSnapshotsWithTest top4Only={true} />
        <CountriesInfectionRates top4Only={true} />
        <WorldSnapshot />
        <CovidTimelineCard />
      </Container>

      <Container>
        <SocialShare
          title="COVID-19 insights"
          description="COVID-19 realtime data, statistical insights, event timeline. See which countries are doing well and which ones are not."
          url="/covid-19/insights/"
        />

        <ECVBanner />

        <CollectQAndA />
      </Container>
    </PageContainer>
  </Layout>
);

export default Covid19InsightsPage;
