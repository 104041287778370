import React, { Fragment } from "react";
import { AskAboutQuery_SourceData } from "../../../../graphql-types";
import { getEventAttributes, EventCategory } from "../../../utils/analytics";
import styles from "./source-link.styles";

interface SourceLinkProps {
  eventCategory: EventCategory;
  sources: AskAboutQuery_SourceData[];
  isDataOnly?: boolean;
}

const SourceLink: React.FC<SourceLinkProps> = ({
  sources,
  isDataOnly,
  eventCategory,
}) => {
  if (sources.length === 0) {
    return null;
  }

  return (
    <div>
      <div css={styles.container}>
        {isDataOnly ? "Data:" : "Source:"}
        {sources.map(({ url, title }, index) => (
          <Fragment key={title}>
            {index > 0 && ", "}
            <a
              href={url}
              css={styles.sourceUrl}
              target="_blank"
              rel="noopener noreferrer"
              {...getEventAttributes({
                category: eventCategory,
                action: "Click - Source",
                label: title,
              })}
            >
              {title}...
            </a>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default SourceLink;
